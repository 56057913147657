import axios from 'axios'
import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import * as XLSX from 'xlsx'
import DatePicker from 'react-multi-date-picker'
import { DateObject } from 'react-multi-date-picker'
import Loader from 'react-js-loader'
import loader from '../icons/loder.gif'
import { apiRequest } from '../store/allApi'

const $ = require('jquery')
$.Datatable = require('datatables.net')

function Earings () {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API
  const nodeMode = process.env.NODE_ENV
  if (nodeMode === 'development') {
    var baseUrl = beckendLocalApiUrl
  } else {
    var baseUrl = beckendLiveApiUrl
  }

  //use for pagination..
  let [limit, setLimit] = useState(10)
  const [loading, setLoading] = useState(false)
  const [pageNumber, setPageNumber] = useState(0)
  const [numberOfPages, setNumberOfPages] = useState(0)

  const setpageLimit = event => {
    let key = event.target.value
    setLimit(key)
  }

  const [EARING, setEARING] = useState()
  const [TOTELEARING, setTOTELEARING] = useState()

  const Year = new Date().getFullYear();
  const Month = new Date().getMonth() + 1; // Adding 1 to the month since getMonth() returns 0-11
  
  const formattedMonth = Month < 10 ? `0${Month}` : `${Month}`;
  
  const dynamicStartDate = `${Year}/${formattedMonth}/01`;
  const dynamicEndDate = `${Year}/${formattedMonth}/31`;
  
  const [startDate, setStartDate] = useState(dynamicStartDate);
  const [endDate, setEndDate] = useState(dynamicEndDate);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  //react paginate..
  const handlePageClick = async data => {
    let currentPage = data.selected + 1
    setPageNumber(currentPage)
    // scroll to the top
    //window.scrollTo(0, 0)
  }

  const getCases = async () => {
    const access_token = localStorage.getItem('token')
    setLoading(true)
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
 
    try {
      const res = await apiRequest(`admin/Earning?page=${pageNumber}&_limit=${limit}&FROM_DATE=${startDate}&TO_DATE=${endDate}`,"GET",null,headers)
         setLoading(false)
        setEARING(res.data.admin)
        setNumberOfPages(res.data.totalPages)
        //$('table').dataTable();
        } catch (e) {
          setLoading(false)
        }
        finally {
          setLoading(false)
        }


    try {
      const res = await apiRequest('admin/Earning/total',"GET",null,headers)
       setLoading(false)
      setTOTELEARING(res.data.total)
        } catch (e) {
          console.log(e)
        }
        finally {
          setLoading(false)
        }
  };

  useEffect(() => {
    getCases()

  }, [pageNumber, limit, startDate, endDate])

  const createDownloadData = () => {
    handleExport()
  }
  const handleExport = () => {
    let table1 = [
      {
        A: 'id',
        B: 'Amount'
        // F:"Bonus by (ID)"
      }
    ]

    EARING.forEach(row => {
      const Earing = row
      console.log('exldata', Earing)
      table1.push({
        A: `Earn From Challange ${Earing.Earned_Form} on ${Earing.createdAt}`,
        B: Earing.Ammount
        // F:(userDetails.action_by)?userDetails.action_by:'N/A'
      })
    })

    //table1 = [{A:"User Details"}].concat(table1);
    //const finalData = [...table1];
    //console.log(finalData);
    /* convert state to workbook */
    const ws = XLSX.utils.json_to_sheet(table1, {
      skipHeader: true
    })
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'SheetJS')
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, 'AdminEarning.xlsx')
  }

  return loading ? (
    <>
       <div className='App'>
        <div className='item dashboaed_loader'>
          <Loader
            type='bubble-ping'
            bgColor={'#fff'}
            title={<img src={loader} alt='Loading...' />}
            color={'#000'}
            size={100}
          />
        </div>
      </div>
    </>
  ) : (
    <>
      <div className='row '>
        <div className='col-12 grid-margin'>
          <div className='card text-white'>
            <div className='card-body text-light table_bg'>
              <div className='mt-3'>
                <h3 className='d-flex flex-row dashboard_heading'>
                  Admin Total Earing : ₹ {TOTELEARING}
                </h3>
                <div className='d_flex_both_center'>
                  <select
                    className='form-control col-sm-1 m-1'
                    id='pagelimit'
                    name='pagelimit'
                    onChange={setpageLimit}
                  >
                    <option value='10'>Set limit</option>
                    <option value='20'>20</option>
                    <option value='50'>50</option>
                    <option value='100'>100</option>
                    <option value='500'>500</option>
                  </select>
                  <div className=''>
                    {/* <DatePicker
                      value={values}
                      onChange={setValues}
                      range
                      style={{ height: '40px' }}
                    /> */}
                               <DatePicker
        selected={startDate}
        value={startDate}
        onChange={handleStartDateChange}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        placeholderText="Start Date"
        style={{ height: '40px' }}
      />
      {/* <span style={{color:"black"}}></span> */}
      <DatePicker
        selected={endDate}
        onChange={handleEndDateChange}
        value={endDate}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        placeholderText="End Date"
        minDate={startDate} // Ensure that the end date cannot be before the start date
        style={{ height: '40px' }}
      />
                    <button
                      onClick={() => {
                        createDownloadData()
                      }}
                      className='btn btn-primary ml-2'
                      style={{ height: '40px' }}
                    >
                      Export Data
                    </button>
                  </div>
                </div>
              </div>

              <div className='table-responsive'>
                <table className='table text-white'>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th> _id </th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {EARING &&
                      EARING.map((Earing, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <div className='d-flex'>
                              <span className='pl-2'>
                                Earn From Challange {Earing.Earned_Form} on{' '}
                                {Earing.createdAt}
                              </span>
                            </div>
                          </td>

                          <td>
                            <div className=''>{Earing.Ammount}</div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className='mt-4'>
                <ReactPaginate
                  previousLabel={'Previous'}
                  nextLabel={'Next'}
                  breakLabel={'...'}
                  pageCount={numberOfPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={'pagination justify-content-center'}
                  pageClassName={'page-item'}
                  pageLinkClassName={'page-link'}
                  previousClassName={'page-item'}
                  previousLinkClassName={'page-link'}
                  nextClassName={'page-item'}
                  nextLinkClassName={'page-link'}
                  breakClassName={'page-item'}
                  breakLinkClassName={'page-link'}
                  activeClassName={'active'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Earings
