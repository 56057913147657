import axios from "axios";
import CryptoJS from 'crypto-js';
import { createNewHmac, verifyHmac } from './hmacUtils'


const API_BASE_URL = "https://api.probattle.in"; // replace with your API base URL

const CALLBACK_HASH_SECRET_KEY = 'xmrygl9awugdwaam0vvpw3n6bvhofabnxscle8jwj9odm4rbpr9fyqd7vy31dmkq'; // Replace with your actual secret key



// API request function
export const apiRequest = async (endpoint, method, data = null, headers = {}) => {
  const url = `${API_BASE_URL}/${endpoint}`; // Ensure the URL is constructed properly
  const access_token = localStorage.getItem('token')

  const options = {
    method: method,
    headers: {
      ...(data instanceof FormData ? {} : { "Content-Type": "application/json" }),
      ...headers,
    },
  };


  // Handle POST and PUT requests with request body
  if (data) {

    let dataHmac = data

    const hmac = createNewHmac(dataHmac);
    if (data instanceof FormData) {
      data.append('userHmac', hmac);
    } else {
      data.userHmac = hmac;
    }
    options.data = data;
  }
  else {
    const hmacPayload = { token: `Bearer ${access_token}` };
    const hmac = createNewHmac(hmacPayload);

    options.headers.userHmac = hmac;
  }

  try {
    const response = await axios(url, options);

    if (!response) {
      throw new Error(`Error: ${response.status}`);
    }

    return response;
  } catch (error) {
    console.error("API request failed:", error);
    throw error;
  }
};
