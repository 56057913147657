import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Login from '../../user-pages/Login';
import Dashboard from '../../dashboard/Dashboard';

const Api = () => {
  const [data1, setData1] = useState(null);
  const [data2, setData2] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Function to fetch data from multiple APIs
    const fetchData = async () => {
      try {
        const [response1, response2] = await Promise.all([
          axios.get('https://api.probattle.in/admin/count_rejected/withdrawl'),
          axios.get('https://api.example.com/data2')
        ]);

        setData1(response1.data);
        setData2(response2.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
      <h1>Parent Component</h1>
      <Dashboard dashboard={data1} />
      {/* <ChildComponent2 data={data2} /> */}
    </div>
  );
};

export default Api;
