import React, { Component, useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import { Form } from 'react-bootstrap'
import axios from 'axios'
import logos from '../../assets/images/probattle-small.png'
import { apiRequest } from '../store/allApi'


const Login = ({data}) => {
  const history = useHistory()

  // const [Password, setPassword] = useState();
  // const [otp, setOtp] = useState(false)
  const [Phone, setPhone] = useState()
  const [twofactor_code, settwofactor_code] = useState()
  const [otp, setOtp] = useState(false)
  const [secretCode, setSecretCode] = useState()

  // console.log(FirstName);
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API
  const nodeMode = process.env.NODE_ENV
  if (nodeMode === 'development') {
    var baseUrl = beckendLocalApiUrl
  } else {
    var baseUrl = beckendLiveApiUrl
  }


  const handleClick = async (e) => {
    e.preventDefault();
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    if (!Phone) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter your phone number'
      });
      return; // Exit the function early if no phone number is provided
    } else {
      try {
        const response = await apiRequest("admin/login/admin", "POST", {Phone},headers);
        console.log("Attempting to call the API..."); // Debug log
        console.log("API response received:", response); // Debug log
  
        if (response) {
          if (response.status === 101) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: response.msg
            });
          } else if (response.status === 200) {
            setOtp(true);
            setSecretCode(response.data?.secret);
  
            if (response.myToken) {
              Swal.fire({
                icon: 'success',
                title: 'OTP',
                text: 'OTP For Test Login: ' + response.myToken
              });
            }
          }
        }
      } catch (e) {
        console.error("Error occurred:", e); // Log the error for debugging
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong'
        });
      }
    }
  };
  

  const varifyOtp = async e => {
    e.preventDefault()
    console.log('verify otp sumbut req')
    if (!Phone) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter your phone number'
      })
    } else {
      const res = await apiRequest(`admin/login/admin/complete`, "POST",  {
        Phone,
        twofactor_code,
        secretCode
      }, {})

      console.log(res)

      if (res.status == 101) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: res.msg
        })
      } else if (res.status == 200) {
        const token = res?.data?.token
        localStorage.setItem('token', token);
        window.location.replace('/dashboard');
      }
      else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong! 1'
        })
        .catch(e => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!'
          })
        })
      }
    }

  }

  const setError = () => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Invalid Number',
      confirmation: true
    })
  }

  const [WebSitesettings, setWebsiteSettings] = useState('')

  const fetchData = () => {
    return fetch(baseUrl + 'settings/data')
      .then(response => response.json())
      .then(data => setWebsiteSettings(data))
  }
  //console.log(WebSitesettings);
  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div>
      <div className='container-fluid'>
        <div className='row login_form_main_div'>
          <div className='col-lg-6 col-md-6 col-sm-12'>
            <div>
              <div className='brand-logo'>
                <img src={logos} alt='' className='img-fluid' style={{marginTop:"10px", maxWidth:"115px"}}/>
              </div>
              <div className='form_div'>
                <h1>Login</h1>
                <h6 className=''>Login in to continue</h6>
                <div>
                  <Form className='pt-7 admin_login_form'>
                    <Form.Group className='d-flex search-field '>
                      <Form.Control
                        type='text'
                        placeholder='Username'
                        size='lg'
                        maxLength={'10'}
                        className='h-auto'
                        onChange={e => setPhone(e.target.value)}
                      />
                    </Form.Group>
                    {otp && (
                      <div className='login_input_field_main otp_filed'>
                        <div className='login_input_field_inner'>
                          <span>OTP</span>
                          <input
                            className='form_control input_field'
                            name='password'
                            type='tel'
                            placeholder='Enter OTP'
                            onChange={e => settwofactor_code(e.target.value)}
                          />
                        </div>
                      </div>
                    )}
                    {!otp && (
                      <button
                        className='Login-button btn  cxy mt-4'
                        onClick={e => handleClick(e)}
                      >
                        Get OTP
                      </button>
                    )}
                    {otp && (
                      <button
                        className='Login-button btn btn-dark cxy mt-4'
                        onClick={e => varifyOtp(e)}
                      >
                        Verify
                      </button>
                    )}
                  </Form>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 login_img_box'>
            {/* <img src='./login.svg' className='img-fluid login_img_animation' /> */}
            <img src='./login-form-img.png' className='img-fluid login_img_animation' style={{width:"450px"}} />
          </div>
        </div>
      </div>
      {/* <div className='d-flex align-items-center auth px-0'>
        <div className='row w-100 mx-0'>
          <div className='col-lg-4 mx-auto'>
            <div className='card text-left py-5 px-4 px-sm-5'>
              <div className='brand-logo'>
                <img src={baseUrl + WebSitesettings.Logo} alt='' />
              </div>
              <h4>Login {WebSitesettings.WebsiteName} Admin</h4>
              <h6 className='font-weight-light'>Sign in to continue.</h6>
              <Form className='pt-7 admin_login_form'>
                <Form.Group className='d-flex search-field '>
                  <Form.Control
                    type='text'
                    placeholder='Username'
                    size='lg'
                    maxLength={'10'}
                    className='h-auto'
                    onChange={e => setPhone(e.target.value)}
                  />
                </Form.Group>
                {otp && (
                  <div
                    className='bg-white  cxy flex-column'
                    style={{
                      height: '60px',
                      borderRadius: '5px',
                      marginTop: '10px'
                    }}
                  >
                    <div
                      className='input-group mb-2'
                      style={{ transition: 'top 0.5s ease 0s', top: '5px' }}
                    >
                      <div className='input-group-prepend'>
                        <div
                          className='input-group-text'
                          style={{
                            backgroundColor: '#e9ecef',
                            border: '1px solid #d8d6de'
                          }}
                        >
                          OTP
                        </div>
                      </div>
                      <input
                        className='form-control h-auto'
                        name='password'
                        type='tel'
                        placeholder='Enter OTP'
                        onChange={e => settwofactor_code(e.target.value)}
                        style={{
                          transition: 'all 3s ease-out 0s',
                          borderRadius: '4px',
                          border: '1px solid #d8d6de'
                        }}
                      />
                    </div>
                  </div>
                )}
                {!otp && (
                  <button
                    className='Login-button btn btn-dark cxy mt-4'
                    onClick={e => handleClick(e)}
                  >
                    Next
                  </button>
                )}
                {otp && (
                  <button
                    className='Login-button btn btn-dark cxy mt-4'
                    onClick={e => varifyOtp(e)}
                  >
                    Verify
                  </button>
                )}
              </Form>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default Login
