import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import * as XLSX from 'xlsx'
import DatePicker from 'react-multi-date-picker'
import moment from 'moment'; 
import { DateObject } from 'react-multi-date-picker'
import admindata from '../../components/admindata'
import { apiRequest } from '../../store/allApi'

const $ = require('jquery')
$.Datatable = require('datatables.net')

const PenaltyReport = () => {
  const [user, setUser] = useState()

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API
  const nodeMode = process.env.NODE_ENV
  if (nodeMode === 'development') {
    var baseUrl = beckendLocalApiUrl
  } else {
    baseUrl = beckendLiveApiUrl
  }

  const searchHandler = (event) => {
    let key = event.target.value;
    setSearchList(key);
  };

  //user for searching..
  const [searchList, setSearchList] = useState(0);
  const [searchType, setSearchType] = useState(0);

  //use for pagination..
  let [limit, setLimit] = useState(10)

  const setpageLimit = event => {
    let key = event.target.value
    setLimit(key)
  }
  const [pageNumber, setPageNumber] = useState(0)
  const [numberOfPages, setNumberOfPages] = useState(0)
  const Year = new Date().getFullYear();
  const Month = new Date().getMonth() + 1; // Adding 1 to the month since getMonth() returns 0-11
  
  const formattedMonth = Month < 10 ? `0${Month}` : `${Month}`;
  
  const dynamicStartDate = `${Year}/${formattedMonth}/01`;
  const dynamicEndDate = `${Year}/${formattedMonth}/31`;
  
  const [startDate, setStartDate] = useState(dynamicStartDate);
  const [endDate, setEndDate] = useState(dynamicEndDate);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  //react paginate..
  const handlePageClick = async data => {
    let currentPage = data.selected + 1
    setPageNumber(currentPage)
    // scroll to the top
    //window.scrollTo(0, 0)
  }

  const profle =async () => {
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    
    try {
      const res = await apiRequest(`txn/penaltyreports/all?page=${pageNumber}&_limit=${limit}&_q=${searchList}&_stype=${searchType}&FROM_DATE=${startDate}&TO_DATE=${endDate}`,"GET", null,headers)
     if (res.data) {
              setUser(res.data.datefind)
        setNumberOfPages(res.data.totalPages)
        //$('table').dataTable();
    
     }
        } catch (e) {
          // setLoading(false)
        }
        finally {
        }
  }

  const dateFormat = e => {
    const date = new Date(e)
    const newDate = date.toLocaleString('default', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    })
    return newDate
  }


  useEffect(() => {
    const instance = profle();
  
    return () => {
      if (instance && typeof instance.destroy === 'function') {
        instance.destroy();
      }
    };
  }, [pageNumber, limit, startDate, endDate, searchList, searchType])


  if (user === undefined) {
    return null
  }

  const createDownloadData = () => {
    handleExport()
  }
  const handleExport = () => {
    let table1 = [
      {
        A: 'Id',
        B: 'UserName',
        C: 'PhoneNumber',
        D: 'Penalty Amount',
        E: 'Penalty By'
        // F:"Penalty by (ID)"
      }
    ]

    user.forEach(row => {
      const userDetails = row
      console.log('exldata', userDetails)
      table1.push({
        A: userDetails._id,
        B: userDetails.User_id ? userDetails.User_id.Name : '',
        C: userDetails.User_id ? userDetails.User_id.Phone : '',
        D: userDetails.amount,
        E: userDetails.action_by ? userDetails.action_by.Name : 'N/A'
        // F:(userDetails.action_by)?userDetails.action_by:'N/A'
      })
    })

    //table1 = [{A:"User Details"}].concat(table1);
    //const finalData = [...table1];
    //console.log(finalData);
    /* convert state to workbook */
    const ws = XLSX.utils.json_to_sheet(table1, {
      skipHeader: true
    })
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'SheetJS')
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, 'PenaltyReports.xlsx')
  }
  return (
    <>
      {/* <h4 className='font-weight-bold my-3'>ALL CHALLANGES</h4> */}
      <div className='row mt-5'>
        <div className='col-12 grid-margin'>
          <div className='card'>
            <div className='card-body  table_bg'>
              <h4 className='dashboard_heading'>Penalty Reports</h4>
              <div className="row mb-4">
                <div className="col-md-2 col-sm-12">
                  <select
                    className="form-control  "
                    id="pagelimit"
                    name="pagelimit"
                    onChange={setpageLimit}
                  >
                    <option value="10">Set limit</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                  </select>
                </div>
                <div className="col-md-2 col-sm-12">
                  <select
                    className="form-control "
                    id="searchType"
                    name="searchtype"
                    onChange={(e) => setSearchType(e.target.value)}
                  >
                    <option value="0">Select Search by</option>
                    <option value="Name">Name</option>
                    <option value="Phone">Phone</option>
                    <option value="_id">User Id</option>
                    <option value="refferal_code">Refferal Id</option>
                  </select>
                </div>
                <div className="col-md-2 col-sm-12">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="form-control "
                    onChange={searchHandler}
                  />
                </div>
                <div className="col-md-4 col-sm-12 ">
                  <div className="d-flex">

                
                  <DatePicker
                    selected={startDate}
                    value={startDate}
                    onChange={handleStartDateChange}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText="Start Date"
                    style={{ height: "36px" }}
                  /> 
                  <h4  className="text-dark m-2"> To</h4>
                  <DatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    value={endDate}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText="End Date"
                    minDate={startDate} // Ensure that the end date cannot be before the start date
                    style={{ height: "36px" }}
                  />
                  </div>
                </div>
               
                <div className="col-md-2 col-sm-12">
                  <button
                    onClick={() => {
                      createDownloadData();
                    }}
                    className="btn btn-primary sm-ml-0 ml-4 "
                    style={{ height: "40px" }}
                  >
                    Export Data
                  </button>
                </div>
              </div>
              <div className='table-responsive'>
                <table className='table text-light'>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th> ID</th>
                      <th> Phone</th>
                      <th> User</th>
                      <th> Amount </th>
                      <th> Reason </th>
                      <th> Status </th>
                      <th> Date </th>
                      <th>Penalty by</th>
                      {/* <th>Penalty by Id</th> */}
                    </tr>
                  </thead>

                  <tbody>
                    {user &&
                      user.map((data, key) => (
                        <tr>
                          <td>{key + 1}</td>
                          <td>{data._id}</td>
                          <td>
                            {admindata?.user?.user_type == 'Admin' ||
                            admindata?.user?.updateLegalData === true ? (
                              <span className='pl-2'>
                                {data.User_id ? data.User_id.Phone : ''}
                              </span>
                            ) : (
                              <span className='pl-2'>{`${
                                '********' +
                                data.User_id.Phone.toString().slice(-2)
                              }`}</span>
                            )}
                          </td>
                          <td>
                            {data.User_id && (
                              <Link
                                className='btn btn-sm btn-outline-info'
                                to={`/user/view_user/${data.User_id._id}`}
                              >
                                {data.User_id.Name}
                              </Link>
                            )}
                          </td>

                          <td>{data.amount}</td>
                          <td>{data.txn_msg}</td>
                          <td className='font-weight-bold text-success'>
                            {data.status}
                          </td>
                          <td>{dateFormat(data.createdAt).split(',')[0]} </td>
                          <td>
                            {data.action_by ? data.action_by.Name : 'N/A'}
                          </td>
                          {/* <td>{(data.action_by)?data.action_by:'N/A'}</td> */}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>

              <div className='mt-4'>
                <ReactPaginate
                  previousLabel={'Previous'}
                  nextLabel={'Next'}
                  breakLabel={'...'}
                  pageCount={numberOfPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={'pagination justify-content-center'}
                  pageClassName={'page-item'}
                  pageLinkClassName={'page-link'}
                  previousClassName={'page-item'}
                  previousLinkClassName={'page-link'}
                  nextClassName={'page-item'}
                  nextLinkClassName={'page-link'}
                  breakClassName={'page-item'}
                  breakLinkClassName={'page-link'}
                  activeClassName={'active'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PenaltyReport
